<template>
  <div class="all_main_wrap">
    <!-- 个人 -->
    <div class="all_content_box">
      <div class="Up_Down_inner">
        <div class="bgDiv">
          <div class="line_item">
            <p>姓名：<span>{{idList.name}}</span></p>
            <p>身份证号：<span>{{ idList.idNo ? $encryption('user:infomation:sensitivequery','idCard',idList.idNo) : '-' }}</span></p>  
            <p>性别：<span>{{idList.sex == 1?'男':idList.sex == 2?'女':'未知'}}</span></p>
          </div>
          <div class="line_item">
            <p>用户名：<span>{{idList.userName}}</span></p>
            <p>学历：<span>{{idList.degree}}</span></p>
            <p>身份：<span>{{ idList.userType == 1?'家长':idList.userType == 2?'从业者':idList.userType == 3?'机构':idList.userType == 4?'家长+从业者':idList.userType == 5?'家长+机构':idList.userType == 6?'从业者+机构':idList.userType == 0?'普通用户':'家长+从业者+机构'}}</span></p>
          </div>
          <div class="line_item">
            <p>籍贯：<span>{{idList.pgAddressName ? idList.pgAddressName:'-'}}</span></p>
            <p>所在地：<span>{{idList.locationName ? idList.locationName:'-'}}</span></p>
            <p>民族：<span>{{idList.nation}}</span></p>
          </div>
          <div class="line_item">
            <p>工作年限：<span>{{idList.workYears ? idList.workYears:'-'}}</span></p>
            <p>所在行业：<span>{{idList.industry ? idList.industry + ' - ' + idList.industrySub : '-'}}</span></p>
            <p>职业：<span>{{idList.occupationName}}</span></p>
          </div>
          <div class="line_item">
            <p>工作单位：<span>{{idList.workName ? idList.workName:'-'}}</span></p>
            <p>手机号：<span>{{ idList.mobile ? $encryption('user:infomation:sensitivequery','phone',idList.mobile) : '-' }}</span></p>  
            <p>孩子生日：<span>{{idList.childBirthday}}</span></p>
          </div>
          <div class="line_item">
            <p>注册来源：<span>{{idList.loginType ? idList.loginType == 1?'PC':idList.loginType == 2?'安卓':idList.loginType == 3?'ios':'-':'-'}}</span></p>
          </div>
        </div>
      </div>
      <!-- <div class="Up_Down_inner">
        <span class="all_left_name">绑定信息</span>
        <div class="bgDiv">
          <div class="line_item">
            <p><span>欣欣康复机构</span></p>
          </div>
        </div>
      </div> -->
    </div>

    <!-- 机构 -->
    <div class="all_content_box" v-show="false">
      <div class="Up_Down_inner">
        <span class="all_left_name">个人信息</span>
        <div class="bgDiv">
          <div class="line_item">
            <p>姓名：<span>王大大</span></p>
            <p>身份证号：<span>21211122111211212121</span></p>
            <p>性别：<span>男</span></p>
          </div>
          <div class="line_item">
            <p>用户名：<span>王大大不挑食</span></p>
            <p>学历：<span>本科</span></p>
            <p>身份：<span>从业人员</span></p>
          </div>
          <div class="line_item">
            <p>籍贯：<span>辽宁省锦州市</span></p>
            <p>所在地：<span>辽宁省沈阳市沈北新区34号</span></p>
            <p>民族：<span>汉</span></p>
          </div>
          <div class="line_item">
            <p>工作年限：<span>2年</span></p>
            <p>所在行业：<span>互联网</span></p>
            <p>职业：<span>康复师</span></p>
          </div>
          <div class="line_item">
            <p>工作单位：<span>北京华夏元道文化科技有限公司</span></p>
            <p>手机号：<span>188888888</span></p>
          </div>
        </div>
      </div>

      <div class="Up_Down_inner">
        <span class="all_left_name">实名信息</span>
        <div class="bgDiv">
          <div class="line_item">
            <p>机构名称：<span>北京华xxxxx科技有限公司</span></p>
            <p>机构码：<span>864686</span></p>
            <p>所在地：<span>北京朝阳区</span></p>
          </div>
          <div class="line_item">
            <p>注册人机构职位：<span>董事长</span></p>
            <p>统一社会信用代码：<span>A78586A3125X156</span></p>
          </div>
        </div>
      </div>
      <div class="Up_Down_inner">
        <span class="all_left_name">绑定学员</span>
        <div class="table-box">
          <a-table
            class="table-template"
            :rowKey="item=>item.id"
            :columns="columns"
            :data-source="tableData"
            :bordered="true"
            :pagination="false"
            >
            <template slot="index" slot-scope="item, row, i">
              <div style="text-align: center;">
                {{ i + 1 }}
              </div>
            </template>
          <template slot="operation" >
            <div class="btn_router_link">
              <router-link to @click.native="goPage">详情</router-link>
            </div>
          </template>
        </a-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const columns = [
  {
    title: "序号",
    width: "80px",
    align:'center',
    dataIndex: "index",
    scopedSlots: { customRender: 'index' }

  },
  {
    title: "用户名",
    align:'center',
    dataIndex: "code1"
  },
  {
    title: "姓名",
    align:'center',
    dataIndex: "code2"
  },
  {
    title: "手机号",
    align:'center',
    dataIndex: "code3"
  },
];
export default {
  // 可用组件的哈希表
  components: {},
  // 接收传值
  props: {
    	idList: {
				type: Object,
				default: () => {},
			},
  },
  // 数据对象
  data () {
    return {
      columns,
      tableData:[]
    }
  },
  // 事件处理器
  methods: { },
  // 生命周期-实例创建完成后调用
  created () {
    // 生成假数据
    for(let i=1; i<7; i++){
      this.tableData.push({
        id:i,code1:'数据' + i, code2:'数据' + i, code3:'数据' + i })
    }
  },
  // 生命周期-实例挂载后调用
  mounted () { },
  // 生命周期-实例销毁离开后调用
  destroyed () { },
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {}
}
</script>

<style lang="less" scoped>
.Up_Down_inner{
  margin-bottom: 10px;
}
.all_main_wrap{
  padding: 0 16px;
}
.table-box{
  width: 800px;
}
.bgDiv{
  .line_item{
    display: flex;
    margin-bottom: 24px;
    p{
      width: calc(100% / 3);
      font-size: 14px;
      font-weight: 400;
      color: #666666;
      span{
        font-weight: 500;
        color: #333333;
      }
    }
  }
  .line_item:last-child{
    margin-bottom: 0;
  }
}
</style>
